<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="review-page z-index">
            <h1 class="title d-flex justify-content-between align-items-center">Отзывы наших пользователей <router-link to="/reviews/add" class="button">Добавить отзыв</router-link></h1>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4" v-for="review in getReviewsList" :key="review">
                    <div class="review-wrap">
                        <div class="review-title">
                            <img :src="review.photo" :alt="review.fullname">
                            <span>{{ review.fullname }}</span>
                        </div>
                        <p class="review-text">{{ review.message }}</p>
                        <small class="review-date">{{ review.date }}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Header,
        Footer
    },
    computed: {
        getReviewsList() {
            return (this.$store.getters.getReviewsList)
            ? this.$store.getters.getReviewsList
            : this.$store.dispatch('getReviewsList')
        }
    }
}
</script>