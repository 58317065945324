export default {
    state: {
        Main: '',
        FAQ: '',
        currencies: '',
        fiat: ''
    },
    mutations: {
        setMain(state, payload) {
            state.Main = payload
        },
        setFAQ(state, payload) {
            state.FAQ = payload
        },
        setCurrencies(state, payload) {
            state.currencies = payload.currencies
        },
        setFiatCurrencies(state, payload) {
            state.fiat = payload.currencies
        },
    },
    actions: {
        async getCurrencies({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/currencies'})
            .then(response => response.json())
            .then(data => {
                commit('setCurrencies', data)
            })
        },
        async getFiatCurrencies({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/currencies/fiat'})
            .then(response => response.json())
            .then(data => {
                commit('setFiatCurrencies', data)
            })
        },
        async getMainData({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/index'})
            .then(response => response.json())
            .then(data => {
                commit('setMain', data)
            })
        },
        async getMainFAQ({dispatch, commit}) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/faq'})
            .then(response => response.json())
            .then(data => {
                commit('setFAQ', data)
            })
        }
    },
    getters: {
        getMainData(state) {
            return state.Main
        },
        getMainFAQ(state) {
            return state.FAQ
        },
        getCurrencies(state) {
            return state.currencies
        },
        getFiatCurrencies(state) {
            return state.fiat
        }
    }
}