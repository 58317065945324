import axios from 'axios'

export default {
    state: {
        baseUrl: 'https://api.bitok.kg/',
        language: (localStorage.lang) ? localStorage.lang : 'ru',
        requestData: '',
    },
    mutations: {
        setLanguage(state, value) {
            state.language = value
        },
        setRequestResponse(state, data) {
            state.requestData = data
        }
    },
    actions: {
        sendRequest({commit, state}, data) {
            return new Promise((resolve, reject) => {
                commit('clearNotification')
                commit('setLoading', true)
                const finalUrl = (data.baseUrl) ? data.baseUrl : state.baseUrl + state.language + data.endpoint
                fetch(finalUrl, {
                    method: data.method ? data.method : 'POST',
                    timeout: 8000,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data.data)
                })
                .then(response => {
                    commit('setLoading', false)
                    resolve(response)
                })
                .catch((err) => {
                    reject(err)
                })
            })
        },
        sendFileRequest({commit, state}, data) {
            return new Promise((resolve, reject) => {
                commit('clearNotification')
                commit('setLoading', true)
                const finalUrl = (data.baseUrl) ? data.baseUrl : state.baseUrl + state.language + data.endpoint
                axios.post(finalUrl,
                    data.formData,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then((response) => response)
                .then((result) => {
                    commit('setLoading', false)
                    resolve(result)
                })
                .catch((error) => {
                    commit('setLoading', false)
                    reject(error)
                })
            })
        }
    },
    getters: {
        getBaseUrl(state) {
            return state.baseUrl
        },
        getLanguage(state) {
            return state.language
        },
        getRequestData(state) {
            return state.requestData
        }
    }
}