<template>
    <section class="mobile">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-6">
                    <img src="../../assets/img/mobile.png" alt="Mobile App" class="mobile-app-img">
                </div>
                <div class="col-12 col-md-12 col-lg-6">
                    <h1>Обменяйте средства за считаные минуты</h1>
                    <p>Широкий спектр услуг по торговле и управлению цифровыми активами для удовлетворения разнообразных торговых потребностей</p>
                    <router-link to="/dashboard/buy/" class="mobile-button button">Обмен</router-link>
                </div>
            </div>
        </div>
    </section>
</template>