<template>
    <div class="dashboard-wrap">
        <h1 class="dashboard-title">Оплата услуг</h1>
        <div class="dashboard-list">
            <div class="row" v-if="servicesList">
                <router-link v-for="item in servicesList" :key="item" :to="'/dashboard/payment-services/' + item.id" class="col-12 col-md-4 col-lg-4 col-xl-4 payment-service-wrap">
                    <div class="list-item">
                        <img :src="item.logo ? item.logo : PaymentIcon" :alt="item.name">
                        <div class="list-item-wrap">
                            <div>
                                <span class="text-white active">{{ item.name }}</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="row" v-else>
                <span class="icon-text empty">Нет данных</span>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentIcon from '../../assets/img/icons/box.svg'

export default {
    data() {
        return {
            PaymentIcon: PaymentIcon,
            searchValue: '',
        }
    },
    mounted() {
        this.$store.dispatch('getPaymentServices')
    },
    computed: {
        servicesList() {
            return this.$store.getters.getPaymentServices
        }
    },
    methods: {
        search(item) {
            return (item.name.toLowerCase().search(this.searchValue.toLowerCase()) !== -1 || item.currency.toLowerCase().search(this.searchValue.toLowerCase()) !== -1) ? true : false
        }
    }
}
</script>