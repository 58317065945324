<template>
    <div class="modal-window" v-if="modalStatus">
        <div class="modal-close" v-on:click="closeModal"></div>
        <div class="modal-content">
            <h1 class="modal-title-v2">{{ data.title }}</h1>
            <div class="form-group mt-2" v-if="data.search !== false">
                <input type="text" class="form-input" placeholder="Поиск" v-model="searchValue">
            </div>
            <div class="dashboard-table modal-scroll">
                <template v-for="(item, index) in data.list" :key="item">
                    <div class="dashboard-table-row table-row-v2 select" v-if="search(item)" :class="(selectValue === item[data.ListValue] || selectValue === index) ? 'active' : ''" v-on:click="selection(item, index)">
                        <div class="dashboard-table-column">
                            <span v-if="data.ListName && item[data.ListName]">{{ item[data.ListName] }}</span>
                            <b v-if="data.LabelName && data.LabelValue && item[data.LabelValue]">{{ String(data.LabelName).replace('%s', item[data.LabelValue]) }}</b>
                        </div>
                        <div class="dashboard-table-column">
                            <span class="select-checkbox"></span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchValue: '',
            selectValue: false
        }
    },
    props: {
        data: Array
    },
    created() {
        this.$store.dispatch('setSelect', {name: '', value: ''})
    },
    computed: {
        modalStatus() {
            return this.$store.getters.getModalStatus
        }
    },
    watch: {
        modalStatus() {
            this.searchValue = ''
        }
    },
    methods: {
        closeModal() {
            this.$store.dispatch('setModalStatus', false)
        },
        selection(item, index) {
            this.selectValue = this.data.ListValue ? item[this.data.ListValue] : index
            this.$store.dispatch('setSelect', {name: item[this.data.ListName], value: this.selectValue})
            this.closeModal()
        },
        search(item) {
            const searchName = String(item[this.data.ListName])
            return (searchName.search(this.searchValue) !== -1 || searchName.toLowerCase().search(this.searchValue) !== -1) ? true : false
        }
    }
}
</script>