<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="dashboard-main z-index">
            <LeftBar></LeftBar>
            <div class="right-bar">
                <template v-if="profile?.verification && profile.verification?.value !== '2'">
                    <div class="text danger mb-4">
                        <p class="mb-2"><b>Внимание</b>, Ваш аккаунт не верифицирован, пройдите проверку KYC чтобы получить доступ ко всем функциям платформы:</p>
                        <div class="mb-2">Доступно:</div>
                        <div>✅ Пополнение</div>
                        <div class="mt-3 mb-2">Недоступно:</div>
                        <div>❌ Вывод</div>
                        <div>❌ Покупка</div>
                        <div>❌ Продажа</div>
                        <router-link to="/dashboard/settings/verification" class="button button-success mt-3">Пройти проверку KYC</router-link>
                    </div>
                </template>
                <router-view></router-view>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import LeftBar from '@/components/Account/LeftBar.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Header,
        LeftBar,
        Footer
    },
    computed: {
        profile() {
            return (this.$store.getters.getProfile)
            ? this.$store.getters.getProfile
            : this.$store.dispatch('getProfile')
        }
    }
}
</script>