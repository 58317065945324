<template>
    <h1 class="dashboard-title justify-content-between">
        <span>Купить криптовалюту</span>
        <div>
            <input type="text" name="search" class="form-input" v-model="searchValue" placeholder="Поиск" autocomplete="off">
        </div>
    </h1>
    <div class="currencies-table">
        <div class="row currencies-row border-bottom">
            <div class="col-6 col-md currencies-column m">Торговая пара</div>
            <div class="col-md currencies-column">Цена</div>
            <div class="col-md currencies-column">Рынок</div>
            <div class="col-6 col-md currencies-column m">Действие</div>
        </div>
        <template v-for="currency in currencies" :key="currency">
            <div class="row currencies-row border-bottom" v-if="currency.can_buy === true && search(currency)">
                <div class="col-6 col-md currencies-column currencies-column-md m">
                    <img :src="currency.logo" :alt="currency.name">
                    <span>USDT/{{ currency.currency }}</span>
                </div>
                <div class="col-md currencies-column currencies-column-md">${{ currency.rate }}</div>
                <div class="col-md currencies-column currencies-column-md">
                    <apexchart type="area" width="120" height="40" :options="chartOptions" :series="rateFormat(currency)"></apexchart>
                </div>
                <div class="col-6 col-md currencies-column currencies-column-md m">
                    <router-link :to="'/dashboard/buy/' + currency.currency" class="button button-success">Купить</router-link>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'

export default {
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            searchValue: '',
            chartOptions: {
                chart: {
                    toolbar: {
                        show: false
                    },
                    sparkline: {
                        enabled: true
                    },
                    parentHeightOffset: 0,
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        colorStops: [
                            {
                                offset: 60,
                                color: "#46E3AC",
                                opacity: 0.1
                            },
                            {
                                offset: 70,
                                color: "#46E3AC",
                                opacity: 0.08
                            },
                            {
                                offset: 100,
                                color: "transparent",
                                opacity: 1
                            }

                        ]
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    show: false,
                    labels: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: {
                    labels: {
                        show: false,
                    }
                },
                grid: {
                    show: false
                },
                stroke: {
                    width: 1,
                    colors: ["#46E3AC"],
                    curve: 'smooth',
                    // curve: ['smooth', 'straight', 'stepline']
                },
                tooltip: {
                    enabled :false
                }
            }
        }
    },
    computed: {
        currencies() {
            return this.$store.getters.getCurrencies
        },
    },
    methods: {
        rateFormat(data) {
            const rates = []
            Object(data.rates).forEach((item, index) => {
                rates[index++] = item.price
            })
            return [{data: rates}]
        },
        search(item) {
            return (item.currency.toLowerCase().search(this.searchValue.toLowerCase()) !== -1 || item.name.toLowerCase().search(this.searchValue.toLowerCase()) !== -1) ? true : false
        }
    }
}
</script>