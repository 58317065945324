<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="news-page z-index">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-8">
                    <div class="news-wrap" v-if="NewsDetail">
                        <span class="news-title">{{ NewsDetail.name }}</span>
                        <div class="news-thumbnail">
                            <img :src="NewsDetail.photo" :alt="NewsDetail.name">
                        </div>
                    </div>
                    <p class="news-description active" v-html="NewsDetail.body"></p>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    data() {
        return {
            NewsId: ''
        }
    },
    components: {
        Header,
        Footer
    },
    created() {
        if (this.$router.currentRoute.value.params.id) {
            this.$store.dispatch('getNewsDetail', this.$router.currentRoute.value.params.id)
        }
    },
    computed: {
        NewsDetail() {
            return this.$store.getters.getNewsDetail
        }
    }
}
</script>