<template>
    <Header></Header>
    <Slider></Slider>
    <Rates></Rates>
    <About></About>
    <Currencies></Currencies>
    <Features></Features>
    <News></News>
    <Mobile></Mobile>
    <FAQ></FAQ>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Slider from '@/components/Main/Slider.vue'
import Rates from '@/components/Main/Rates.vue'
import About from '@/components/Main/About.vue'
import Currencies from '@/components/Main/Currencies.vue'
import Features from '@/components/Main/Features.vue'
import News from '@/components/Main/News.vue'
import Mobile from '@/components/Main/Mobile.vue'
import FAQ from '@/components/Main/FAQ.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Header,
        Slider,
        Rates,
        About,
        Currencies,
        Features,
        News,
        Mobile,
        FAQ,
        Footer
    }
}
</script>