<template>
    <div class="header">
        <div class="header-menu">
            <div class="container" :class="menuStatus && 'z-index'">
                <router-link to="/dashboard" class="logo" v-if="dashboardStatus"></router-link>
                <router-link to="/" class="logo" v-else></router-link>
                <div class="header-nav" :class="{ 'active': menuStatus, 'header-dash': dashboardStatus }">
                    <template v-if="isAuth">
                        <router-link v-for="item in menu" :key="item" :to="item.link" class="menu md-flex">
                            <b>{{ item.name }}</b>
                        </router-link>
                        <a class="menu md-flex" v-on:click="logout"><b>Выйти</b></a>
                    </template>
                    <router-link to="/" class="menu">
                        <b>Главная</b>
                        <span>Главная</span>
                    </router-link>
                    <router-link to="/about" class="menu">
                        <b>О компании</b>
                        <span>О компании</span>
                    </router-link>
                    <router-link to="/news" class="menu">
                        <b>Новости</b>
                        <span>Будь в курсе</span>
                    </router-link>
                    <router-link to="/faq" class="menu">
                        <b>FAQ</b>
                        <span>Вопрос/Ответы</span>
                    </router-link>
                    <router-link to="/reviews" class="menu">
                        <b>Отзывы</b>
                        <span>Что о нас говорят?</span>
                    </router-link>
                    <router-link to="/contacts" class="menu">
                        <b>Контакты</b>
                        <span>Свяжитесь с нами</span>
                    </router-link>
                    <template v-if="dashboardStatus">
                        <div class="account-balance ml-auto" v-if="balance.USDT">
                            <div class="account-balance-wrap">
                                <span>Ваш баланс</span>
                                <b>{{ balance.USDT.balance }}</b>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <a href="#" class="auth-menu ml-auto" v-if="isAuth" v-on:click="logout">Выйти</a>
                        <router-link to="?page=login" class="auth-menu ml-auto" v-else>Войти</router-link>
                        <router-link to="/dashboard" class="auth-menu active" v-if="isAuth">Личный кабинет</router-link>
                        <router-link to="?page=registration" class="auth-menu active" v-else>Регистрация</router-link>
                    </template>
                </div>
                <div class="mobile-menu" v-on:click="showMenu" :class="menuStatus && 'open'">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
        <div class="header-garland"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isAuth: false,
            menuStatus: false,
            dashboardStatus: false,
            menu: [
                { name: 'Личный кабинет', icon: 'home', link: '/dashboard' },
                { name: 'Пополнить', icon: 'refill', link: '/dashboard/refill/cryptocurrency' },
                { name: 'Купить', icon: 'buy', link: '/dashboard/buy', label: 'Торговля' },
                { name: 'Продать', icon: 'sell', link: '/dashboard/sell' },
                { name: 'Вывести', icon: 'withdraw', link: '/dashboard/withdraw' },
                { name: 'История операции', icon: 'history', link: '/dashboard/operations' },
                { name: 'Настройки', icon: 'settings', link: '/dashboard/settings', label: 'Настройки' }
            ]
        }
    },
    created() {
        if (localStorage.getItem('isAuth') === 'true' && localStorage.getItem('token')) {
            this.isAuth = true
        }
        if (String(this.$router.currentRoute.value.path).search('dashboard') !== -1) {
            this.dashboardStatus = true
        }
    },
    computed: {
        balance() {
            return (this.$store.getters.getCurrencies)
                ? this.$store.getters.getCurrencies
                : this.$store.dispatch('getCurrencies')
        }
    },
    watch: {
        $route() {
            this.menuStatus = false
        }
    },
    methods: {
        showMenu() {
            this.menuStatus = (this.menuStatus) ? false : true
        },
        logout() {
            this.$store.dispatch('logout')
            this.isAuth = false
        }
    }
}
</script>