import router from "@/router"

export default {
    state: {

    },
    mutations: {

    },
    actions: {
        async registration({dispatch, commit}, formData) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/auth/registration', data: formData})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    router.push('?page=activation&email=' + formData.email)
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        async activation({dispatch, commit}, formData) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/auth/activation', data: formData})
            .then(response => response.json())
            .then(data => {
                if(data.response === true && data.token) {
                    localStorage.expires = Math.round(Date.now() / 1000) + + 3600;
                    localStorage.isAuth = true
                    localStorage.token = data.token;
                    router.push('/dashboard')
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        async resend({dispatch, commit}, formData) {
            dispatch('sendRequest', {endpoint: '/auth/activation/resend', data: formData})
            .then(response => response.json())
            .then(data => {
                if(data.response !== undefined && data.response === true) {
                    commit('notification', {message: data.messages, type: 'success'})
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        async login({dispatch, commit}, formData) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/auth/login', data: formData})
            .then(response => response.json())
            .then(data => {
                if(data.response !== undefined && data.response === true) {
                    // localStorage.expires = data.expires;
                    localStorage.expires = Math.round(Date.now() / 1000) + + 3600;
                    localStorage.isAuth = true
                    localStorage.token = data.token;
                    commit('notification', {message: 'You have successfully logged in', type: 'success'})
                    commit('twofactor', false)
                    router.replace('/dashboard')
                } else {
                    if(data.not_activated === true) {
                        router.push('/?page=activation&email=' + formData.email)
                        commit('notification', {message: data.messages})
                    } else {
                        if(data.security === '2fa') {
                            commit('twofactor', true)
                        }
                        commit('notification', {message: data.messages})
                    }
                }
            })
        },
        async restore({dispatch, commit}, formData) {
            dispatch('sendRequest', {endpoint: '/auth/forgot', data: formData})
            .then(response => response.json())
            .then(data => {
                if(data.response !== undefined && data.response === true) {
                    commit('notification', {message: data.messages, type: 'success'})
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        logout() {
            localStorage.removeItem('expires')
            localStorage.removeItem('isAuth')
            localStorage.removeItem('token')
            router.push('/?page=login')
        }
    },
    getters: {
        
    }
}