<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="about-page">
            <div class="about-info-wrap">
                <div class="about-info">
                    <h1 class="title pb-4">🚀 Добро пожаловать в Bitok.kg</h1>
                    <p class="about-text pb-4">Ваш надежный партнер в мире криптовалюты по всему Кыргызстану! Мы предлагаем выгодные курсы, стабильность и конфиденциальность в каждой операции. Присоединяйтесь к нам уже сегодня и начните эффективно управлять своими цифровыми активами! 😊💼</p>
                    <div class="row about-stats">
                        <div class="col-6 col-md-4">
                            <span>Основана в</span>
                            <b>2022</b>
                        </div>
                        <div class="col-6 col-md-4">
                            <span>Страны и регионы</span>
                            <b>170+</b>
                        </div>
                        <div class="col-6 col-md-4">
                            <span>Наши пользователи</span>
                            <b>10+ тысяч</b>
                        </div>
                    </div>
                </div>
                <img src="../../assets/img/about-img.png" alt="">
            </div>
            <h1 class="title pb-3">Наша миссия</h1>
            <p class="about-text">Наша миссия заключается в том, чтобы сделать криптовалютные операции доступными и безопасными для всех. Мы стремимся предоставить нашим клиентам простые и удобные инструменты для торговли криптовалютами, обеспечивая при этом высокий уровень безопасности и прозрачности.</p>
            <p class="about-text"><b class="text-white">Покупка криптовалют:</b><br> Мы предоставляем возможность быстро и безопасно приобрести популярные криптовалюты, такие как Bitcoin, Ethereum, и многие другие.</p>
            <p class="about-text"><b class="text-white">Продажа криптовалют:</b><br> Легко и удобно продайте свои криптовалюты по выгодным курсам.</p>
            <p class="about-text pb-3"><b class="text-white">Консультации и поддержка:</b><br> Наша команда экспертов всегда готова помочь вам с любыми вопросами и предоставить профессиональные консультации по всем аспектам работы с криптовалютами.</p>
            <h1 class="title">Почему выбирают нас</h1>
            <div class="row about-features">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="features-wrap">
                        <div class="features-title">
                            <i class="features-icon x-icon-security"></i>
                            <span class="features-name">Надежность и безопасность</span>
                        </div>
                        <p class="features-text">Мы используем современные технологии и методы защиты, чтобы обеспечить безопасность ваших средств и данных.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="features-wrap">
                        <div class="features-title">
                            <i class="features-icon x-icon-free"></i>
                            <span class="features-name">Прозрачность</span>
                        </div>
                        <p class="features-text">Все операции проводятся честно и прозрачно, без скрытых комиссий и условий.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="features-wrap">
                        <div class="features-title">
                            <i class="features-icon x-icon-card"></i>
                            <span class="features-name">Удобство</span>
                        </div>
                        <p class="features-text">Интуитивно понятный интерфейс нашего сайта позволяет легко совершать операции даже начинающим пользователям.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Header,
        Footer
    }
}
</script>