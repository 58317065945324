import { createStore } from 'vuex'
import Select from './select'
import Shared from './shared'
import Security from './security'
import Api from './api'
import Main from './main'
import News from './news'
import FAQ from './faq'
import Reviews from './reviews'
import Auth from './auth'
import Dashboard from './dashboard'
import Refill from './refill'
import Buy from './buy'
import Sell from './sell'
import Withdraw from './withdraw'
import History from './history'
import Profile from './profile'
import PaymentService from './payment-services'
import Rates from './rates'
import Points from './points'

const store = createStore({
    modules: {
        Select,
        Shared,
        Security,
        Api,
        Main,
        News,
        FAQ,
        Reviews,
        Auth,
        Dashboard,
        Refill,
        Buy,
        Sell,
        Withdraw,
        History,
        Profile,
        PaymentService,
        Rates,
        Points
    }
})

export default store