<template>
    <section class="slider">
        <div class="container slider-main">
            <div class="row w-100">
                <div class="col-md-6 col-sm-12 slider-main-text">
                    <h1>Обмен криптовалют в Бишкеке</h1>
                    <span>Лучший сервис по продаже и покупке криптовалют (Bitcoin, USDT и др.) за доллары и сомы!</span>
                    <div class="main-btns-wrap">
                        <template v-if="isAuth">
                            <router-link to="/dashboard" class="main-button active">Перейти в личный кабинет</router-link>
                        </template>
                        <template v-else>
                            <router-link to="?page=registration" class="main-button">Регистрация</router-link>
                            <router-link to="?page=login" class="main-button active">Войти в личный кабинет</router-link>
                        </template>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="calc-wrap">
                        <div class="calc-header">
                            <a href="https://t.me/bitokkg" target="_blank" class="calc-header-text">Присоединяйтесь к нашей Telegram группе!</a>
                        </div>
                        <div class="calc-body">
                            <div class="calc-tabs">
                                <button :class="type == 'buy' && 'active'" v-on:click="type = 'buy'">Купить</button>
                                <button :class="type == 'sell' && 'active'" v-on:click="type = 'sell'">Продать</button>
                            </div>
                            <div class="calc-form" v-if="type == 'buy'">
                                <div class="form-group">
                                    <label>Вы отдаете</label>
                                    <div class="calc-input-group">
                                        <input type="number" class="calc-form-input" v-model="sendAmount" @input="calculate" pattern="[0-9]*" min="0" max="1000000000" placeholder="Введите сумму">
                                        <div class="calc-currency-wrap none-icon basic-width">
                                            <div class="calc-currency-icon">
                                                <img :src="sendCurrency.logo" :alt="sendCurrency.name">
                                            </div>
                                            <div class="calc-currency-text">
                                                <b>{{ sendCurrency.currency }}</b>
                                                <span>{{ sendCurrency.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Вы получаете</label>
                                    <div class="calc-input-group">
                                        <input type="number" class="calc-form-input" v-model="receiveAmount" @input="calculateReserve" pattern="[0-9]*" min="0" max="1000000000" placeholder="Введите сумму">
                                        <div class="calc-currency-wrap none-icon basic-width" v-if="receiveCurrency">
                                            <div class="calc-currency-icon">
                                                <img :src="receiveCurrency.logo" :alt="receiveCurrency.name">
                                            </div>
                                            <div class="calc-currency-text">
                                                <b>{{ receiveCurrency.currency }}</b>
                                                <span>{{ receiveCurrency.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="badge badge-success" v-if="receiveCurrency.buy_fees">Комиссия на покупку {{ String(receiveCurrency.buy_fees).replace('-', '+') }}%</p>
                                <router-link to="?page=login" class="button block lg" v-if="!isAuth">Купить сейчас</router-link>
                                <router-link to="/dashboard/buy" class="button block lg" v-else>Купить сейчас</router-link>
                            </div>
                            <div class="calc-form" v-if="type == 'sell'">
                                <div class="form-group">
                                    <label>Вы отдаете</label>
                                    <div class="calc-input-group">
                                        <input type="number" class="calc-form-input" v-model="sendAmount" @input="calculate" pattern="[0-9]*" min="0" max="1000000000" placeholder="Введите сумму">
                                        <div class="calc-currency-wrap none-icon basic-width">
                                            <div class="calc-currency-icon">
                                                <img :src="sendCurrency.logo" :alt="sendCurrency.name">
                                            </div>
                                            <div class="calc-currency-text">
                                                <b>{{ sendCurrency.currency }}</b>
                                                <span>{{ sendCurrency.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Вы получаете</label>
                                    <div class="calc-input-group">
                                        <input type="number" class="calc-form-input" v-model="receiveAmount" @input="calculateReserve" pattern="[0-9]*" min="0" max="1000000000" placeholder="Введите сумму">
                                        <div class="calc-currency-wrap none-icon basic-width">
                                            <div class="calc-currency-icon">
                                                <img :src="receiveCurrency.logo" :alt="receiveCurrency.name">
                                            </div>
                                            <div class="calc-currency-text">
                                                <b>{{ receiveCurrency.currency }}</b>
                                                <span>{{ receiveCurrency.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="badge badge-success" v-if="sendCurrency.sell_fees">Комиссия на продажу {{ String(sendCurrency.sell_fees).replace('-', '+') }}%</p>
                                <router-link to="?page=login" class="button block lg" v-if="!isAuth">Продать сейчас</router-link>
                                <router-link to="/dashboard/sell" class="button block lg" v-else>Продать сейчас</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import USDIcon from '../../assets/img/icons/usd.png'

export default {
    data() {
        return {
            isAuth: false,
            modalStatus: false,
            type: 'buy',
            sendAmount: '',
            sendCurrency: '',
            receiveAmount: '',
            receiveCurrency: '',
            currencyList: [
                {
                    logo: USDIcon,
                    name: 'US Dollar',
                    currency: 'USD'
                }
            ]
        }
    },
    mounted() {
        if (this.currencyList[0]) {
            this.sendCurrency = this.currencyList[0]
        }
        if (this.$store.getters.getCurrencies) {
            this.receiveCurrency = this.currencies.USDT
        }
        if (localStorage.getItem('isAuth')) {
            this.isAuth = true
        }
    },
    computed: {
        currencies() {
            return (this.$store.getters.getCurrencies)
                ? this.$store.getters.getCurrencies
                : this.$store.dispatch('getCurrencies')
        },
        fiat() {
            return (this.$store.getters.getFiatCurrencies)
                ? this.$store.getters.getFiatCurrencies
                : this.$store.dispatch('getFiatCurrencies')
        }
    },
    watch: {
        currencies() {
            // this.sendAmount = 1000
            this.receiveCurrency = this.currencies.USDT
            this.calculate()
        },
        type() {
            if (this.type == 'buy') {
                this.sendCurrency = this.currencyList[0]
                this.receiveCurrency = this.currencies.USDT
            }
            if (this.type == 'sell') {
                this.sendCurrency = this.currencies.USDT
                this.receiveCurrency = this.currencyList[0]
            }
            this.calculate()
        }
    },
    methods: {
        numberFormat(number, float) {
            var string, result
            if (String(number).includes('.')) {
                string = String(number).split('.')
                result = (float > 0) ? string[0] + '.' + string[1].substring(0, float) : string[0]
            } else {
                result = number
            }
            return result
        },
        modal(status) {
            this.modalStatus = status
        },
        selectSendCurrency(item) {
            this.modalStatus = false
            this.sendCurrency = item
            this.calculate()
        },
        selectReceiveCurrency(item) {
            this.modalStatus = false
            this.receiveCurrency = item
            this.calculate()
        },
        calculate() {
            if (this.type == 'buy') {
                const commission = (this.sendAmount * parseFloat(this.receiveCurrency.buy_fees)) / 100
                const result = this.sendAmount - commission
                this.receiveAmount = result > 0 ? this.numberFormat(result, 2) : ''
            }
            if (this.type == 'sell') {
                const commission = (this.sendAmount * parseFloat(this.sendCurrency.sell_fees)) / 100
                const result = this.sendAmount - commission
                this.receiveAmount = result > 0 ? this.numberFormat(result, 2) : ''
            }
        },
        calculateReserve() {
            if (this.type == 'buy') {
                const commission = (this.receiveAmount * parseFloat(this.receiveCurrency.buy_fees)) / 100
                const result = this.receiveAmount + + commission
                this.sendAmount = result > 0 ? this.numberFormat(result, 2) : ''
            }
            if (this.type == 'sell') {
                const commission = (this.receiveAmount * parseFloat(this.sendCurrency.sell_fees)) / 100
                const result = this.receiveAmount + + commission
                this.sendAmount = result > 0 ? this.numberFormat(result, 2) : ''
            }
        }
    }
}
</script>