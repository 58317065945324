<template>
    <section class="about">
        <div class="container about-container">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-4">
                    <span>Ведущая платформа для покупки и продажи криптовалют</span>
                </div>
                <div class="col-12 col-md-12 col-lg-7">
                    <p>Широкий спектр услуг по торговле и управлению цифровыми активами для удовлетворения разнообразных торговых потребностей. Всесторонняя защита пользовательских активов с нашим автономным хранилищем, многофакторным шифрованием и круглосуточным мониторингом безопасности.</p>
                </div>
            </div>
        </div>
    </section>
</template>