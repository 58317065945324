<template>
  <Notification></Notification>
  <Modal></Modal>
  <div id="snow-container">
    <audio autoplay="true" loop id="audio">
      <source :src="sound">
    </audio>
  </div>
  <router-view></router-view>
</template>

<script>
import Notification from '@/components/Notification.vue'
import Modal from '@/components/Modal.vue'
import Christmas from '@/assets/files/christmas.mp3'

export default {
  name: 'App',
  components: {
    Notification,
    Modal
  },
  data() {
    return {
      sound: Christmas
    }
  },
  created() {
    const self = this
    setInterval(function () {
      if (localStorage.getItem('isAuth') && Math.round(Date.now() / 1000) > localStorage.getItem('expires')) {
        self.$store.dispatch('logout')
        self.$router.push('/?page=login&SessionExpired=true')
      }
      if (!navigator.onLine || window.addEventListener('offline', null)) {
        self.$store.dispatch('notification', { message: 'Отсутствует подключение к интернету' })
      }
    }, 3000)
  },
  mounted() {
    this.snowEffect()
    // setTimeout(function() {
    //   document.getElementById('audio').play()
    // }, 1500)
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = 'Купить и продать криптовалюту в Бишкеке. Обмен Биткоина, USDT и др. на доллары | Bitok.kg'
      }
    },
  },
  methods: {
    snowEffect() {
      const snowContainer = document.getElementById("snow-container")
      const snowContent = ['&#10052', '&#10053', '&#10054']
      const random = (num) => {
        return Math.floor(Math.random() * num);
      }
      const getRandomStyles = () => {
        const top = random(100);
        const left = random(100);
        const dur = random(10) + 10;
        const size = random(25) + 1;
        return `top:-${top}%;left:${left}%;font-size:${size}px;animation-duration:${dur}s;color:#fff;`;
      }
      const createSnow = (num) => {
        for (var i = num; i > 0; i--) {
          var snow = document.createElement("div");
          snow.className = "snow";
          snow.style.cssText = getRandomStyles();
          snow.innerHTML = snowContent[random(2)]
          snowContainer.append(snow);
        }
      }
      createSnow(80)
    }
  }
}
</script>
