<template>
    <div class="dashboard-personal-wrap">
        <div class="profile-info-column before-none mt-0">
            <div v-if="security">
                <b class="mb-1">Google Authenticator</b>
                <span>Используется для ввода кода подтверждения Google при снятии и изменении настроек безопасности</span>
                <router-link to="/dashboard/settings/security/2fa" class="button button-danger mt-3" v-if="security['2fa']">Выключить</router-link>
                <router-link to="/dashboard/settings/security/2fa" class="button button-success mt-3" v-else>Включить</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        security() {
            return (this.$store.getters.getProfile)
            ? this.$store.getters.getProfile.security
            : ''
        }
    }
}
</script>