export default {
    // state: {
    //     fiatRate: ''
    // },
    // mutations: {
    //     setFiatRates(state, payload) {
    //         state.fiatRate = payload.currencies
    //     }
    // },
    // actions: {
    //     async getFiatRates({dispatch, commit}) {
    //         dispatch('sendRequest', {method: 'GET', endpoint: '/currencies/fiat'})
    //         .then(response => response.json())
    //         .then(data => {
    //             commit('setFiatRates', data)
    //         })
    //     },
    // },
    // getters: {
    //     getFiatRates(state) {
    //         return state.fiatRate
    //     }
    // }
}